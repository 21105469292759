<template>
  <div class="header-container">
    <div class="logo-tabs">
      <div
        class="menu-home"
        @click="
          () => {
            $router.push({ path: '/index' });
          }
        "
      >
        <i class="iconfont icon-logo-04 home-logo"></i>
        <!-- <img class="home-logo" src="../../assets/images/bangke_logo.png" alt="icon" /> -->
      </div>
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#fff"
          text-color="#666"
          active-text-color="#1890FF"
          router
        >
          <el-menu-item index="/index">后台管理</el-menu-item>
          <!-- <el-menu-item index="2">任务工单</el-menu-item> -->
          <el-menu-item index="/header/cloud">云文档</el-menu-item>
          <el-menu-item v-if="getUserInfo.company_id == 23" index="/quoted/quotes">报价中心</el-menu-item>
          
          <el-menu-item index="/assign-order/receive-list">
            <el-badge v-if="waitOrderNum" :value="waitOrderNum" :max="99" class="tabBadge"><span>接单中心</span></el-badge>
            <span v-else>接单中心</span>
          </el-menu-item>
          
          <!-- <el-menu-item v-if="!waitOrderNum" index="/assign-order/receive-list">接单中心</el-menu-item> -->
          <!-- <el-menu-item index="5">消息通知</el-menu-item> -->
        </el-menu>
      </div>
    </div>
    <div class="headerMsg-group">
      <div class="header-item">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <span v-if="getUserIcon"
              ><img class="user-icon" :src="$vuex.getters.getUserIcon"
            /></span>
            <span style="vertical-align: middle" v-if="!getUserIcon"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
            欢迎您，{{ getUserInfo.nick_name }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="$sto.get(Config.constants.haveCompany)"
              ><div
                class="menuBtn"
                @click="
                  routePush(`/company-center?userId=${getUserInfo.user_id}`)"
              >
                公司档案
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="menuBtn" @click="winPush(`/personal-center?userId=${getUserInfo.user_id}`)">
                个人档案
              </div>
            </el-dropdown-item>
            <!-- <el-dropdown-item>
            <div @click="showDrawer(true)">更改密码</div>
          </el-dropdown-item> -->
            <el-dropdown-item>
              <div
                class="menuBtn"
                @click="
                  () => {
                    routePush('/login', 'signOut');
                  }
                "
              >
                退出
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="roleHeader">{{ getUserInfo.roles && getUserInfo.roles[0] }}</div>
      </div>
      <el-badge style="margin-right: 36px" v-if="isReadNum.c0 != 0" :value="isReadNum.c0" :max="99" class="item" >
        <div class="header-item" @click="showDrawer(false)">
          <!-- <i class="el-icon-message-solid"></i> -->
          <img class="notice_icon" src="../../assets/images/notice_icon.png" />
          消息
        </div>
      </el-badge>
      <div  v-if="isReadNum.c0 == 0" class="header-item" @click="showDrawer(false)">
          <!-- <i class="el-icon-message-solid"></i> -->
          <img class="notice_icon" src="../../assets/images/notice_icon.png" />
          消息
        </div>
      <div class="header-item" style="cursor: auto;"> {{ getUserInfo.company_name && getUserInfo.company_name }}</div>
      <el-drawer
        :title="isEditPassword ? '密码修改' : '通知列表'"
        :visible.sync="drawer"
        :append-to-body="true"
        direction="rtl"
        :before-close="handleClose"
      >
        <div class="drawer-container">
          <el-form
            v-if="isEditPassword"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="输入原密码" prop="oldPassword">
              <el-input
                class="common-screen-input"
                placeholder="请输入原密码"
                v-model="ruleForm.oldPassword"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="输入新密码" prop="newPassword">
              <el-input
                class="common-screen-input"
                placeholder="请输入新密码"
                v-model="ruleForm.newPassword"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="验证新密码" prop="verifyPassword">
              <el-input
                class="common-screen-input"
                placeholder="请确认新密码"
                v-model="ruleForm.verifyPassword"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
          <div v-else v-for="item in infoListData" :key="item.date">
            <div class="message-item">
              <div class="message-date">{{ item.date }}</div>
              <div
                class="messageItem"
                v-for="itemList in item.list"
                :key="itemList.id"
                @click="
                  () => {
                    messageRoute(itemList.id, itemList.type);
                  }
                "
              >
                <div class="message-tip">
                  {{
                    itemList.type == 2
                      ? "提醒"
                      : itemList.type == 1
                      ? "公告"
                      : "预警"
                  }}
                  <span class="isRead" v-if="itemList.is_read == 0"></span>
                </div>
                <span v-if="itemList.type == 1">{{
                  itemList.advice_title
                }}</span>
                <span v-else-if="itemList.type == 2">{{
                  itemList.content
                }}</span>
                <span v-else>{{ itemList.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { Config, DateTransform, arrGroup } from "../../utils/index.js";
import { infoList, noReadNum, readOk } from "../../service/common.js";
import { mapState } from "vuex";
export default {
  // props: ["userInfo"],
  data() {
    return {
      Config,
      DateTransform,
      isEditPassword: false, // 判断弹窗-修改密码/消息
      drawer: false, // 右侧弹窗
      infoListData: [],
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        verifyPassword: ""
      }, // form提交数据
      rules: {
        // 校验规则
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "change" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "change" }
        ],
        verifyPassword: [
          { required: true, message: "请确认新密码", trigger: "change" }
        ]
      },
      timer: "", // 定时器
      // comId: this.$sto.get(Config.constants.userInfo).company_id,
      // activeIndex: window.location.pathname
    };
  },

  created() {
    this.noReadNum();
    this.timer = setInterval(() => {
      this.noReadNum();
    }, 180000);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    ...mapState(["isReadNum", "activeIndex","getUserInfo","getUserIcon","haveCompany", "waitOrderNum"])
  },
  methods: {
    winPush(url) {
      window.location.href = url
    },
    async noReadNum() {
      let resData = (await noReadNum()).data
      this.$vuex.commit("isReadNum", resData);
    },
    async messageRoute(id, type) {
      this.drawer = false;
      await readOk({
        infoId: id,
        type: type == "1" || type == "2" ? type : "3"
      });
      this.$router.push({ path: "/message-center-notice", query: { id: id } });
    },
    routePush(route, type) {
      if (type == "signOut") {
        this.$sto.remove(Config.store.token);
        localStorage.clear();
      }
      this.$router.push({ path: route });
    },
    showDrawer(value) {
      this.isEditPassword = value;
      // if (!value) {
      //   this.getInfoList();
      // }
      this.$router.push({ path: "/message-center-notice" });
      // this.drawer = true;
    },
    async getInfoList() {
      let params = { type: 0, pageNum: 1, pageSize: 10 };
      let resData = (await infoList(params)).data;
      this.infoListData = arrGroup(resData.records);
    },
    handleClose(done) {
      done();
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath, "---------");
      // if (keyPath == "/index") {
      //   this.activeIndex = "/index"
      // } else if(keyPath == "/header/cloud") {
      //   this.activeIndex = "/header/cloud"
      // }else{
      //   this.activeIndex = "/quoted/quotes"
      // }
    }
  }
};
</script>
<style lang="less" scoped>
.tabBadge {
  /deep/ .is-fixed {
    top: 20px !important;
    right: 0px !important;
  }
}
/deep/.el-dropdown-menu__item {
  padding: 0 !important;
}
/deep/.el-badge__content.is-fixed {
  top: 5px;
  // line-height: 16px;
  right: 22px;
}
.menuBtn {
  padding: 0 24px;
}
.header-container {
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  // flex-direction: row-reverse;
  align-items: center;
  font-size: 14px;
  .logo-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-home {
      cursor: pointer;
      width: 204px !important;
      height: 64px !important;
      display: flex;
      align-items: center;
      // justify-content: center;
      background-color: rgb(46, 52, 60);
      overflow: hidden;
      .home-logo {
        width: 140px;
        margin-left: 30px;
        color: #fff;
      }
    }
    /deep/ .el-menu-item {
      height: 64px;
      background-color: #fff !important;
      color: #666 !important;
      font-size: 14px;
    }
    /deep/ .is-active {
      background-color: #fff !important;
      color: #666 !important;
    }
  }
  .headerMsg-group {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
  }
  .home_icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 16px;
  }
  .roleHeader {
    display: inline-block;
    color: #1890ff;
    font-size: 14px;
    padding: 4px 8px;
    border: 1px solid #1890ff;
    border-radius: 4px;
    margin: 0 0 0 16px;
    background: #f2f9ff;
    cursor: auto;
  }
  .user-icon {
    width: 38px;
    height: 38px;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 0;
  }
  .header-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
    color: #000000a6;
    position: relative;
    cursor: pointer;
    .common-iconImg-MR{
      margin-right: 4px;
    }
    .el-icon-user-solid {
      border-radius: 50%;
      color: blue;
      background: #eee;
    }
    .el-icon-user-solid,
    .el-icon-headset,
    .el-icon-question,
    .el-icon-message-solid {
      font-size: 20px;
      padding: 5px;
    }
    .notice_icon {
      margin-right: 6px;
      width: 12px;
    }
  }
}
.drawer-container {
  padding: 0 20px;
  .message-item {
    cursor: pointer;
    margin-top: 16px;
    color: #000000a6;
    .message-date {
      font-size: 14px;
      color: #999;
      margin-top: 10px;
    }
    .messageItem {
      padding-left: 22px;
      cursor: pointer;
      &:hover {
        background: rgb(236, 242, 255);
        border-left: 6px solid #3477fd;
        padding-left: 16px;
      }
    }
    .message-tip {
      font-size: 12px;
      color: #333;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #bdbdbd;
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 8px;
      margin-top: 8px;
      position: relative;
      .isRead {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #f98787;
        right: -4px;
        top: -4px;
      }
    }
  }
}
</style>
