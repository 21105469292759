<template>
  <div class="layout-container">
    <el-header class="con-info">
      <header-content></header-content>
    </el-header>
    <el-container class="el-container">
      <el-aside :class="['layout-aside',menuFlag ? 'zero' : 'two']">
        <menu-content :menuInfo="menuInfo"></menu-content>
      </el-aside>
      <el-main :class="[$route.path == '/index' ? 'layout-main2': (menuFlag ? 'layout-main':'layout-main')]" :style="{ padding: (menuFlag ? 0: ($route.path == '/index' ? 0:''))}">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import HeaderContent from "../components/layout/header.vue";
import MenuContent from "../components/layout/menu.vue";
import { userData } from "../service/common.js";
import { Config } from "../utils/index.js";
import { mapState } from "vuex";
export default {
  components: {
    HeaderContent,
    MenuContent
  },
  data() {
    return {
      menuInfo: "",
      userInfo: "",
      timer: ''
    };
  },
  computed: {
    ...mapState(["menuFlag","activeIndex"])
  },
  created() {
    
    this.userData();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async userData() {
      let res = await userData();
      // this.$vuex.dispatch("getMenuInfo");
      if (res.data) {
        this.$vuex.dispatch("waitOrderNum", res.data.company_id);
        this.menuInfo = res.data.menus;
        // 剔除菜单，保留用户信息存储到本地 并传给header组件
        let userInfo = { ...res.data };
        delete userInfo.menus;
        this.$sto.set(Config.constants.userInfo, userInfo);
        this.userInfo = userInfo;
        this.$vuex.commit("getUserIcon", userInfo.icon);
        this.$vuex.commit("getUserInfo", userInfo);
        this.$vuex.dispatch("waitOrderNum", userInfo.company_id);
        this.timer = setInterval(() => {
          this.$vuex.dispatch("waitOrderNum", userInfo.company_id);
        }, 180000);
        if (userInfo && userInfo.roles_company && userInfo.roles_company.length) {
          if( userInfo.roles_company.length == 1) {
            this.$vuex.commit("onlyCompany", true);
          } else {
            this.$vuex.commit("onlyCompany", false);
          }
        }
        
      }
    }
  }
};
</script>
<style lang="less" scoped>
.zero {
  width: 0 !important;
}
.two {
  width: 204px !important;
}
.layout-container {
  background: #eee;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  .el-container{
    flex: 1;
    overflow-y: scroll;
    padding-right: 0;
    width: 100%;
  }
  .layout-main {
    position: relative;
    background: #fff;
    margin: 16px;
    margin-right: 8px;
    // padding: 16px;
    overflow: overlay;
    overflow-x: hidden;
  }
  .layout-main2 {
    margin: 16px;
    margin-right: 8px;
    background: transparent;
    overflow: overlay;
  }
  .el-main {
    padding: 0;
  }

  .con-info {
    height: 64px !important;
    padding: 0;
  }

  .layout-aside {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
