<template>
  <div class="menu-container">
    <el-col :span="24">
      <!-- <div class="menu-home" @click="() => {$router.push({path: '/index'})}">
        <img src="../../assets/images/menu_logo.png" alt="icon" />
        <img src="../../assets/images/menu_en.png" alt="icon" />
      </div> -->
      <el-menu
        ref="menu"
        :router="false"
        unique-opened
        :default-active="getUrl()"
        :default-openeds="defaultOpeneds"
        class="el-menu-vertical-demo"
        background-color="#2e343c"
        text-color="#fff"
        active-text-color="#fff"
      >
        <div v-for="(item, index) in menuInfo" :key="item.id">
          <el-submenu
            :index="index + ''"
            v-if="!item.hideInMenu"
            style="border-bottom: 1px solid #242424"
          >
            <template slot="title">
              <i v-if="item.name == '工单'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="calendar"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '客服'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="customer-service"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M512 128c-212.1 0-384 171.9-384 384v360c0 13.3 10.7 24 24 24h184c35.3 0 64-28.7 64-64V624c0-35.3-28.7-64-64-64H200v-48c0-172.3 139.7-312 312-312s312 139.7 312 312v48H688c-35.3 0-64 28.7-64 64v208c0 35.3 28.7 64 64 64h184c13.3 0 24-10.7 24-24V512c0-212.1-171.9-384-384-384zM328 632v192H200V632h128zm496 192H696V632h128v192z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '知识库'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="database"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-600 72h560v208H232V136zm560 480H232V408h560v208zm0 272H232V680h560v208zM304 240a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm0 272a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm0 272a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '财务'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="money"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v584zM639.5 414h-45c-3 0-5.8 1.7-7.1 4.4L514 563.8h-2.8l-73.4-145.4a8 8 0 0 0-7.1-4.4h-46c-1.3 0-2.7.3-3.8 1-3.9 2.1-5.3 7-3.2 10.9l89.3 164h-48.6c-4.4 0-8 3.6-8 8v21.3c0 4.4 3.6 8 8 8h65.1v33.7h-65.1c-4.4 0-8 3.6-8 8v21.3c0 4.4 3.6 8 8 8h65.1V752c0 4.4 3.6 8 8 8h41.3c4.4 0 8-3.6 8-8v-53.8h65.4c4.4 0 8-3.6 8-8v-21.3c0-4.4-3.6-8-8-8h-65.4v-33.7h65.4c4.4 0 8-3.6 8-8v-21.3c0-4.4-3.6-8-8-8h-49.1l89.3-164.1c.6-1.2 1-2.5 1-3.8.1-4.4-3.4-8-7.9-8z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '营销'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="contacts"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M597.333333 170.666667h-170.666666v42.666666h170.666666V170.666667z m213.333334 256c12.8 0 21.333333 8.533333 21.333333 21.333333 0 8.533333-8.533333 17.066667-17.066667 21.333333H597.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333 0-8.533333 8.533333-17.066667 17.066667-21.333333H810.666667zM362.666667 384C328.533333 384 298.666667 413.866667 298.666667 448S328.533333 512 362.666667 512s64-29.866667 64-64S396.8 384 362.666667 384zM810.666667 597.333333c12.8 0 21.333333 8.533333 21.333333 21.333334 0 8.533333-8.533333 17.066667-17.066667 21.333333H597.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333 0-8.533333 8.533333-17.066667 17.066667-21.333334H810.666667zM362.666667 341.333333C422.4 341.333333 469.333333 388.266667 469.333333 448c0 38.4-21.333333 76.8-55.466666 93.866667 68.266667 21.333333 115.2 81.066667 119.466666 153.6v8.533333h-42.666666c0-72.533333-55.466667-128-128-128-68.266667 0-123.733333 51.2-128 119.466667v8.533333h-42.666667c0-76.8 46.933333-140.8 119.466667-162.133333-34.133333-17.066667-55.466667-55.466667-55.466667-93.866667C256 388.266667 302.933333 341.333333 362.666667 341.333333zM896 256H128v554.666667h768V256z m-298.666667-128c25.6 0 42.666667 17.066667 42.666667 42.666667v42.666666h256c25.6 0 42.666667 17.066667 42.666667 42.666667v554.666667c0 25.6-17.066667 42.666667-42.666667 42.666666H128c-25.6 0-42.666667-17.066667-42.666667-42.666666V256c0-25.6 17.066667-42.666667 42.666667-42.666667h256V170.666667c0-25.6 17.066667-42.666667 42.666667-42.666667h170.666666z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '组织'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="user"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '数据'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="area-chart"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm-616-64h536c4.4 0 8-3.6 8-8V284c0-7.2-8.7-10.7-13.7-5.7L592 488.6l-125.4-124a8.03 8.03 0 0 0-11.3 0l-189 189.6a7.87 7.87 0 0 0-2.3 5.6V720c0 4.4 3.6 8 8 8z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '公告'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="form"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M904 512h-56c-4.4 0-8 3.6-8 8v320H184V184h320c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V520c0-4.4-3.6-8-8-8z"
                  ></path>
                  <path
                    d="M355.9 534.9L354 653.8c-.1 8.9 7.1 16.2 16 16.2h.4l118-2.9c2-.1 4-.9 5.4-2.3l415.9-415c3.1-3.1 3.1-8.2 0-11.3L785.4 114.3c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-415.8 415a8.3 8.3 0 0 0-2.3 5.6zm63.5 23.6L779.7 199l45.2 45.1-360.5 359.7-45.7 1.1.7-46.4z"
                  ></path></svg
              ></i>
              <i v-else-if="item.name == '客服画像'"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="form"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M792.064 754.176V742.4c0.512-113.152-87.552-206.848-199.168-217.6 54.784-30.208 92.16-88.064 92.16-154.112 0-97.792-79.872-177.152-179.2-177.664-98.816 0-179.712 78.848-179.712 176.64 0 67.072 37.888 125.952 93.696 156.16-107.52 14.848-190.464 105.984-190.464 215.552v11.776c0 76.8 102.4 76.8 221.184 77.312l119.808 0.512c114.176-0.512 221.696 0 221.696-76.8z m-394.24-384c0-58.368 49.152-105.984 109.056-105.984s108.544 48.128 108.544 106.496-49.152 105.984-109.056 105.984c-60.416-0.512-108.544-48.128-108.544-106.496z m323.584 378.368c-22.016 11.264-104.96 11.264-150.528 11.264l-119.808-0.512c-52.224 0-130.048-0.512-150.528-11.264v-7.168c0-81.408 68.096-147.456 151.04-146.944l119.808 0.512c82.944 0 150.528 66.56 150.016 147.968v6.144zM901.12 87.04h-130.048c-19.456 0-35.328 15.872-35.328 35.328s15.872 35.328 35.328 35.328H865.28v94.208c0 19.456 15.872 35.328 35.328 35.328 19.456 0 35.328-15.872 35.328-35.328V122.368c0.512-19.456-15.36-35.328-34.816-35.328zM122.368 287.744c19.456 0 35.328-15.872 35.328-35.328V157.696h94.208c19.456 0 35.328-15.872 35.328-35.328S271.36 87.04 251.904 87.04H122.368c-19.456 0-35.328 15.872-35.328 35.328v130.048c0 19.456 15.872 35.328 35.328 35.328zM901.12 735.744c-19.456 0-35.328 15.872-35.328 35.328V865.28h-94.208c-19.456 0-35.328 15.872-35.328 35.328 0 19.456 15.872 35.328 35.328 35.328h130.048c19.456 0 35.328-15.872 35.328-35.328v-130.048c-0.512-18.944-16.384-34.816-35.84-34.816zM252.416 865.792H157.696v-94.208c0-19.456-15.872-35.328-35.328-35.328s-35.328 15.872-35.328 35.328v130.048c0 19.456 15.872 35.328 35.328 35.328h130.048c19.456 0 35.328-15.872 35.328-35.328 0-19.968-15.872-35.84-35.328-35.84z" p-id="3341"/>
                </svg>
              </i>
              <i v-else-if="item.name == '质检'">
                <img class="menu-svg" style="height:1em;width:1em" src="../../assets/images/quality_icon.png" alt="">
              </i>
              <i v-else
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class="menu-svg"
                  data-icon="setting"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z"
                  ></path></svg
              ></i>
              <span>{{ item.name }}</span>
            </template>
            <div
              class="menuTow"
              v-for="itemTwo in item.children"
              :key="itemTwo.path"
            >
              <el-menu-item
                v-if="!itemTwo.hideInMenu && !itemTwo.children"
                style="background-color: transparent"
                :title="itemTwo.remark"
                :index="itemTwo.path"
                @click="() => {menuItemFun(itemTwo.canShow, itemTwo.path)}"
                >{{ itemTwo.name }}</el-menu-item
              >
              <!-- :index="itemTwo.path" -->
              <el-submenu
                :index="item.id + '-' + itemTwo.id"
                v-if="!itemTwo.hideInMenu && itemTwo.children"
              >
                <template
                  slot="title"
                  style="background-color: transparent !important"
                >
                  <span>{{ itemTwo.name }}</span>
                </template>
                <div
                  class="menuThree"
                  v-for="itemThree in itemTwo.children"
                  :key="itemThree.path"
                >
                  <el-menu-item
                    style="background-color: transparent"
                    v-if="!itemThree.hideInMenu"
                    :title="itemThree.remark"
                    :index="itemThree.path"
                    @click="() => {menuItemFun(itemThree.canShow, itemThree.path)}"
                    >{{ itemThree.name }}
                  </el-menu-item>
                </div>
              </el-submenu>
            </div>
          </el-submenu>
        </div>
      </el-menu>
    </el-col>

  </div>
</template>
<script>
// import { menuList } from '../../service/common.js'
// import { Config } from '../../utils/index.js'
import { mapState } from "vuex"
export default {
  props: ["menuInfo"],
  data () {
    return {
      // menuInfo: ''
    }
  },
  created () {
  },
  mounted() {
  },
  computed: {
    ...mapState(["defaultOpeneds"])
  },
  methods: {
    menuItemFun(canShow, path) {
      if (canShow) {
        this.$router.push(path)
      } else {
        // this.$router.push('/index')
        this.openHTML()
        // this.$message.error('暂无权限，请联系平台管理员')
      }
    },
    openHTML() {
      this.$message({
        onClose: () => {},
        dangerouslyUseHTMLString: true,
        showClose: true,
        duration: 2000,
        iconClass: 'el-icon-warning-outline',
        customClass: 'common-tip-alert',
        message: '<div class="tip-title"> <i class="iconfont icon-guanyu"></i>提示</div><div class="tip-info">暂无权限，请联系平台管理员</div>'
      });
    },
    getUrl() {
      let path =  this.$route.path.split("/")
      if(path[1] == 'index'){
        return 
      }else{
        return `/${path[1]}/${path[2]}`
      }
      
      // let pathArr = window.location.pathname.split("/");
      // console.log(pathArr,'pathArr');
      // console.log(this.$refs.menu,'refereferefref');
      // return `/${pathArr[1]}/${pathArr[2]}`;
    },
    // async menuList () {
    //   let menuData = (await menuList()).data
    //   if(menuData) {
    //     this.menuInfo = menuData.menus
    //     let roleInfo = {...menuData}
    //     this.menuInfo = menuData.menus
    //     delete roleInfo.menus
    //     this.$sto.set(Config.constants.userInfo, roleInfo)
    //   }
    // }
  }
};
</script>
<style lang="less" scoped>
.menu-container {
  width: 100%;
  height: 100%;
  background: #2e343c;
  position: relative;
  .vertion { // 版本号
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    margin: auto;
    color: #cacaca;
    font-size: 14px;
    
  }
  // 菜单导航文字左对齐
   /deep/ .el-submenu__title {
    text-align: left;
    color: #ccc !important;
    transition: 1s;
  }
  /deep/  .is-opened .el-submenu__title {
    text-align: left;
    color: #fff !important;
    transition: 0.5s;
  }
  /deep/  .is-opened .menu-svg {
    text-align: left;
    color: #fff !important;
    transition: 1s;
  }

  // 菜单导航item背景色
   /deep/ .el-menu {
    background-color: #1c222b !important;
  }

  // 菜单导航点击后的颜色
  /deep/  .el-menu-item.is-active {
    background: #1890ff !important;
    border: none;
    transition: 1s;
    // margin-right: 10px;
  }
   /deep/ .el-menu-item {
    margin: 8px 0;
    height: 40px !important;
    line-height: 40px !important;
    background-color: #1c222b !important;
  }

  .menuTow {
    /deep/ .el-submenu__title {
      background-color: transparent !important;
    }
    /deep/ .el-menu {
      background-color: #11151a !important;
    }
    /deep/ .el-submenu__title {
      text-align: center !important;
      padding-left: 20px !important;
    }
  }
  .menuThree {
    /deep/ .el-menu-item {
      padding-left: 90px !important;
    }
    /deep/ .el-menu-item.is-active {
      background: #1890ff !important;
      border: none;
      transition: 1s;
    }
    /deep/ .el-menu-item {
      background-color: transparent !important;
    }
  }
  .menu-svg {
    font-size: 14px;
    color: #aaa;
    margin-right: 8px;
    margin-top: -4px;
    transition: 0.5s;
  }
  .menu-home {
    cursor: pointer;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img:nth-of-type(1) {
      margin-right: 16px;
    }
  }
  .el-menu-vertical-demo {
    border: 0;
    background: #2e343c;
  }
}
</style>
